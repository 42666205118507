import React, { useEffect, useState } from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import './PaymentGateway.css'

const NavbarDark = () => {
    const [collectionMenu, setCollectionMenu] = useState('collection')
    const [scroll, setScroll] = useState(false)
    const [navSelect, setNavSelect] = useState(false)
    const [navOpen, setNavOpen] = useState(false)

    const handleOpenNav = () => {
        setNavOpen(true);
    };
    
    const handleCloseNav = () => {
        setNavOpen(false);
    };

    const handleNavChange = () => {
        if(!navSelect === true){
            setNavSelect(true)
        }else{
            setNavSelect(false)
        }
    }

    const handleMouseEnter = (item) => {
        setCollectionMenu(item)
    }

    const handleCollectionShow = () => {
        setCollectionMenu('collection')
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset
            setScroll(scrollTop > 50)
        }
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })
  return (
    <div className=''>
       <div className='headerMain__navcontainers1 w-full flex justify-center items-center'>
               <section className='headerMain__navcontainer1 w-full lg:container mx-auto'>
                 <header className="flex justify-between items-center md:gap-x-10 lg:gap-x-0 px-5 xl:px-10">
                   <div>
                       <a href='/'>
                         <div className="haodaPay__logo">
                           <img src="/images/Haodapay-light-logo.png" alt="img" />
                         </div>
                       </a>
                   </div>            
                   <div className='hidden md:flex items-center gap-x-10 lg:gap-x-14 xl:gap-x-20'>
                     <nav className='navMenu__list1'>
                       <ul className="flex gap-x-5 md:gap-x-8 items-center">
                         <li className="navLinks__name1"><a href='/aboutUs'> About Us</a></li>
                         <li className="navLink__name1" onMouseLeave={handleCollectionShow}>Product
                             <div className='dropDown1'>
                                 <div className='flex justify-between py-5 pr-5 pl-3 gap-x-10'>
                                     <div>
                                         <div className='navLink__product1 flex items-center gap-x-5' onMouseEnter={()=> handleMouseEnter('collection')}>Collection <IoIosArrowForward className='mt-1' /></div>
                                         <a href='/payouts-service-provider-in-india'><div onMouseEnter={()=> handleMouseEnter('')} className='navLink__product1'> Payout </div></a>
                                         <a href='/payment-gateway-services-in-india'><div onMouseEnter={()=> handleMouseEnter('')} className='navLink__product1'> Payment Gateway</div></a>
                                         <a href='/product/partner'><div onMouseEnter={()=> handleMouseEnter('')} className='navLink__product1'> Partner </div></a>
                                     </div>
                                     { collectionMenu === 'collection' && (
                                     <div>
                                         <a href='/collection/education-payment-providers-in-india'><div className='navLink__product1'>Education</div></a> 
                                         <a href='/collection/housing-payments-solutions-in-india'><div className='navLink__product1'>Housing Society</div></a>
                                         <a href='/collection/payment-solutions-for-healthcare'><div className='navLink__product1'>Health Care</div></a>
                                         <a href='/collection/membership-payment-solutions-in-india'><div className='navLink__product1'>Club Association</div></a>
                                     </div> )}
                                 </div>                
                             </div>
                         </li>
                         <li className="navLinks__name1"><a href='/blog'>Blog</a></li>
                         <li className="navLinks__name1"><a href='/career'> Career</a></li>
                         <li className="navLinks__name1"><a href='/contact'>Contact</a></li>
                       </ul>
                     </nav>
                     <a href='https://merchant-beta.mypayhaoda.com/auth/sign-in' target='_blank'><div className="free__btn">
                       <button className='navHeader__btn px-5 py-3 flex items-center gap-x-1'>
                           Free trial
                           <span className='headerBanner__arrowNav1 grid place-content-center'>
                               <FaArrowRight className='headerBannerNav__arrow'/>                                   
                           </span>
                       </button>
                     </div></a>
                   </div>
                   {! navOpen && (
                   <div className='navHeader__menuBtn1  cursor-pointer' onClick={handleOpenNav}>
                       <span>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                           </svg>
                       </span>
                   </div> )}
                 </header>
               </section> 
               </div>  


    {/* Mobile nav menu */}

        <div className={`mobileNav__container1 ${navOpen ? "open" : ""} py-5 px-10`}>
            <div className="flex justify-end">
                <div className="mobileNav__close1 grid place-content-center" onClick={handleCloseNav}>
                    <span><IoCloseSharp /></span>
                </div>
            </div>
            <nav className="mt-5">
                <ul className="navLink__MobileContainer1 flex flex-col gap-y-5">
                    <li className="navLink__Mobilename1"><a href="/aboutUs"> About Us</a></li>
                    <li className="navLink__Mobilena1" onClick={handleNavChange}>
                        <a href="#"></a>
                        <div className="w-full product__name__color1 flex items-center justify-between">
                            Product <IoIosArrowForward className={`${navSelect ? "arrowDown__navRoto1" : "arrowDown__nav1"}`} />
                        </div>
                        <ul className={`${navSelect ? "mobileNav__subMenu1 showNav" : "mobileNav__subMenu1"} pl-3 mt-3`}>
                           <a href='/collection/education-payment-providers-in-india'><li>Education</li> </a>
                           <a href='/collection/housing-payments-solutions-in-india'><li className="my-4">Housing Society</li> </a>
                           <a href='/collection/payment-solutions-for-healthcare'><li>Healthcare</li></a>
                           <a href='/collection/membership-payment-solutions-in-india'><li className="mt-4">Club Association</li></a>
                        </ul>
                    </li>
                    <li className="navLink__Mobilename1"><a href="/payouts-service-provider-in-india">Payout</a></li>
                    <li className="navLink__Mobilename1"><a href="/Payment-Gateway-Services-In-India">Payment Gateway</a></li>
                    <li className="navLink__Mobilename1"><a href="/product/partner">Partner</a></li>
                    <li className="navLink__Mobilename1"><a href="/blog">Blog</a></li>
                    <li className="navLink__Mobilename1"><a href="/career">Career</a></li>
                    <li className="navLink__Mobilename1"><a href="/contact">Contact</a></li>
                </ul>
            </nav>
            <a href='https://merchant-beta.mypayhaoda.com/auth/sign-in' target='_blank'><div className="navLink__MobileBtn1 mt-10">
                <button>Free trial</button>
            </div></a>
        </div>
    </div>
  )
}

export default NavbarDark