import React, { useEffect, useState } from 'react'
import "./contact.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import { Helmet } from "react-helmet"
import { AppKey } from '../constant/AppKey'
import { AxiosFilePost1 } from '../utilities/axiospost'
import { ToastError, ToastSuccess } from '../utilities/toast'
import { IoMdMail } from "react-icons/io";
import { MdCall } from "react-icons/md";

import { IoLocation } from "react-icons/io5";
import axios from 'axios'


const Contact = () => {

      const [selectValue, setSelectValue] = useState("");
      const [loader, setLoader] = useState(false)
      const [error, setError] = useState("");

      const [contactValue, setContactValue] = useState({
              name: "",
              email: "",
              phonenumber: ""
          })
       
      const [contactError, setContactError] = useState({
                name: "",
                email: "",
                phonenumber: ""
          })

       const handleChange = (e) => {
            setSelectValue(e.target.value);
            setError("");

            const { name, value } = e.target
            setContactValue({ ...contactValue, [name] : value})
            setContactError({ ...contactError, [name] : ""})
        };

        // const handleSubmit = (e) => {
        //     e.preventDefault();

        // }

        const validation = () => {
            let valid = true
            const newError = {}
    
            if(!contactValue.name){
                valid = false
                newError.name = "Name is required"
            }else if(contactValue.name.length < 3){
                valid = false
                newError.name = "Minmum 3 letters required"
            }else if(!/^[A-Za-z ]*$/.test(contactValue.name)){
                valid = false
                newError.name = "Only letters are allowed"
            }

            if(!contactValue.email){
                valid = false
                newError.email = "Email id is required"
            }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(contactValue.email)){
                valid = false
                newError.email = "Invalid email address"
            }

            if(!contactValue.phonenumber){
                valid = false
                newError.phonenumber = "Phone number is required"
            }else if(!/^\d{10}$/.test(contactValue.phonenumber)){
                valid = false
                newError.phonenumber = "Invalid phone number"
            }else if(!/^[6789]\d{9}$/.test(contactValue.phonenumber)){
                valid = false
                newError.phonenumber = "Mobile number starting with 6, 7, 8, or 9"
            }

            if (!selectValue) {
                valid = false
                setError("Please Select Value");
            }

            setContactError(newError)

            return valid;
        }

            const handleForm = async (e) => {
                e.preventDefault();
                if (!selectValue) {
                    setError("please Select an value");
                }
            
                if (!validation()) {
                  return;
                }
                setLoader(true)
                
                const allInputValue= {
                    name: contactValue.name,
                    email: contactValue.email,
                    phone_number: contactValue.phonenumber,
                    services: selectValue
                };
                
                console.log(allInputValue);
                try { 
                    // const response = await fetch("http://127.0.0.1:8000/api/user-services", {
                    //   method: "POST",
                    //   headers: {
                    //     accesskey : 'stnemyapadoh',                        
                    //     "Content-Type": "application/json",
                    //   },
                    //   body: JSON.stringify(allInputValue),
                    // });

                    // console.log(response);
                    
              
                    // const result = await response.json();
                    // console.log(result);

                  
                    const res = await axios.post("http://127.0.0.1:8000/api/user-services", allInputValue,
                    { headers:{
                        accesskey : 'stnemyapadoah',                        
                        "Content-Type": "application/json",   
                        maxBodyLength: Infinity,   
                        maxContentLength: Infinity,              
                    }})

                    console.log(res.data);

                    if(res.status == 200){
                        ToastSuccess("Send Successfully. Thank you!")
                        setContactValue({
                            name: "",
                            email: "",
                            phonenumber: ""              
                        })
                        setContactError({
                            name: "",
                            email: "",
                            phonenumber: ""              
                        })
                        
                    }
                    
                    
                  } catch (error) {
                    console.log(error);
                    
                  } finally {
                    setLoader(false)
                };
            };

            // useEffect(() => {handleForm()},[])


  return (
    <div>

    <Helmet>
        <title>Trusted Payment Service Provider in India</title>
        <meta name='tag' content='Top Payment Service Provider in India'></meta>
        <meta name='description' content='Get in touch with us to explore secure and efficient payment solutions. As a top payment service provider in India, we offer tailored services to boost your business '/>
    </Helmet>

        <Navbar />

        <div className='container mx-auto px-5 sm:px-10  md:px-20 lg:px-5 xl:px-40 flex flex-wrap lg:flex-nowrap justify-between gap-x-20 items-center haodapay__certificateBannerTop py-10 sm:py-20'>
            <div className='w-full lg:w-1/2'>
                <span className='contactMain__title block'>Reach out using the form below or contact us directly</span>
                <span className='contactMain__Para block mt-5'>Haoda values your privacy and will use your data only to provide requested information.</span>
                <form onSubmit={handleForm} className='mt-20 flex flex-col gap-5'>
                    <input name='name' type='text' value={contactValue.name} onChange={handleChange} placeholder='Name' className='px-4 block contactForm__input'/>
                    {contactError.name && <span className=" payout__errorMsg block"> {contactError.name} </span>}
                    <input name='email' type='text' value={contactValue.email} onChange={handleChange} placeholder='Email address' className='px-4 block contactForm__input' />
                    {contactError.email && <span className=" payout__errorMsg block"> {contactError.email} </span>}
                    <input name='phonenumber' type='text' value={contactValue.phonenumber} onChange={handleChange} placeholder='Phone number' className='px-4 block contactForm__input' maxLength={10} />
                    {contactError.phonenumber && <span className=" payout__errorMsg block"> {contactError.phonenumber} </span>}
                    
                      <select name='dropdown' id='dropdown' className='px-4 block pr-3 contactForm__input1' value={selectValue} onChange={handleChange} >
                           <option className='contactForm__input1 py-2' value="">Choose your Session</option>
                           <option className='contactForm__input1 py-2' value="Payin">Payin</option>
                           <option className='contactForm__input1 py-2' value="Payout">Payout</option>
                      </select>
                      {error && <span className=" payout__errorMsg block"> {error} </span>}
                    <div className='mt-3 w-full grid place-content-center'>
                  { loader ?  <button className='flex justify-center items-center gap-x-2 loader__btn'>
                                <div className='circleReport'></div>
                                <div className='circleReport1'></div>
                                <div className='circleReport2'></div>
                            </button> : 
                     <button type='submit' className='contactForm__btn'>Send</button> }
                    </div>
                </form>
            </div>
            <div className='w-full lg:w-1/2 mt-10 xl:mt-0 grid place-content-center lg:inline-block'>
                <div className='contact__bannerImg'>
                    <img src='\images\Contact.jpg' alt='img' />
                </div>
            </div>
        </div>

        <div className='py-10 px-5 flex-wrap gap-5 lg:flex-nowrap xl:px-20 flex justify-center'>
            <div className='location_box relative flex justify-center items-center text-center'>
               <div className='grid absolute justify-center gap-2'> <div className='w-full grid justify-center'> <IoLocation className='location_icon block' /></div>
                <span className='block location_subHeading'>Our Address</span>
                <span className='block location_text'>No.853,4th Floor,Thyagaraja Complex</span>
                <span className='block location_text'>P.H.Road,Kilpauk</span>
                <span className='block location_text'>Chennai-600010</span></div>
            </div>

            <div className='location_box flex relative justify-center items-center text-center'>
               <div className='grid absolute justify-center gap-2'> 
                     <div className='w-full grid justify-center'><IoMdMail className='location_icon' /> </div> 
                     <span className='block location_subHeading'>Email Us</span>
                     <span className='block location_text'>info@haodapayments.com</span>
                </div>
            </div>

            <div className='location_box flex justify-center items-center text-center'>
               <div className='grid gap-2'> <div className='w-full grid justify-center'> <MdCall className='location_icon block' /></div>
                <span className='block location_subHeading'>Call Us</span>
                <span className='block location_text'>1800-572-6367</span></div>
            </div>
        </div>

        {/* MAP section */}

        <div class="map">
            <div class="map__iframe">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.3197297406396!2d80.24205028485227!3d13.078911754362148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52667658f0e92f%3A0x52bcd30632d202c5!2sThyagaraja%20Complex%2C%20Kilpauk%2C%20Chennai%2C%20Tamil%20Nadu%20600010!5e0!3m2!1sen!2sin!4v1705061912978!5m2!1sen!2sin" style={{border: "0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        
        <Nextstep />

        <Footer />

    </div>
  )
}

export default Contact