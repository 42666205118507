export const AppKey = Object.freeze({
    IPINFOURL: `https://api.ipify.org/?format=json`, //`https://api.ipify.org/?format=json`//`http://ipinfo.io/?format=json`//
    CLOUDFLAREIPINFOURL: `https://www.cloudflare.com/cdn-cgi/trace`,

    APIURL: "https://crm.payhaoda.com/api/haoda/Leads/create",
    clientId: 'ysisg56w8bdlapcbncfudtdsagg',
    clientSecret: 'haiodghadf76euiassfaesdajhjcg',
    PAYOUTLEAD: "https://crm.payhaoda.com/api/haoda/Leads/create",
    CAREERAPI: "http://139.59.70.152/api/v1/kyc/formsubmit",
    CantactAPI: "http://127.0.0.1:8000/api/user-services"
})